<template>
    <div class="panel has-text-weight-semibold" v-if="getActiveStore.role != 'STAFF'">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">AUTHORIZED USERS</div>
            </div>
        </div>
        <hr>
        <b-table 
            :data='users'
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort=delivery.endDate
            defaultSortDirection='asc'
            >

            <template>

                <b-table-column v-slot="props" class="is-2 has-text-right" field="amount" label="Edit" sortable sorticon>
                <b-button class="is-danger is-small"  v-on:click="authorize('remove',props.row.email)" outlined rounded><i class="far fa-edit"></i></b-button>
                </b-table-column> 

                <b-table-column field="activated" label="Active" v-slot="props">
                    <i class="fas fa-check-circle  has-text-success" v-if="props.row.activated"></i>
                    <i class="fas fa-dot-circle  has-text-danger" v-else></i>
                </b-table-column>

                <b-table-column field="name" label="Name" sortable sorticon v-slot="props">
                    {{ props.row.name }}
                </b-table-column>

                <b-table-column field="email" label="Email" sortable sorticon v-slot="props">
                    {{ props.row.email }}
                </b-table-column>

                <b-table-column field="phoneNumber" label="Phone Number" sortable sorticon v-slot="props">
                    <!-- {{ props.row.phoneNumber | formatUSAPhoneNumber}} -->
                    <span v-if="props.row.phoneNumber"> {{ props.row.phoneNumber | formatUSAPhoneNumber}} </span>
                </b-table-column>

                <b-table-column field="role" label="Role" sortable v-slot="props">
                    {{ props.row.storeUsers[0]['role'] }}
                </b-table-column>

                <!-- <b-table-column class="" v-slot="props">
                    <b-tooltip  v-if="props.row.id != getUser.id"
                        class="is-danger"
                        position="is-left"
                        :label="getDeleteToolTip(props.row.name)"
                        :delay="1000">
                        <i class="button is-danger" v-on:click="authorize('remove',props.row.email)">
                            <i class="fas fa-user-slash"></i>
                        </i>
                    </b-tooltip>
                </b-table-column> -->

            </template>
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>

            <template slot="top-left">
                <a class="button is-primary" v-on:click="authorize('add')">
                    <i class="fas fa-user">&nbsp;&nbsp;Authorize User</i>
                </a>
            </template>

            <template slot="bottom-left">
                <a class="button is-primary" v-on:click="authorize('add')">
                    <i class="fas fa-user">&nbsp;&nbsp;Authorize User</i>
                </a>
            </template>

        </b-table>
        <user-invite
            :storeId="storeId"
            :action="action"
            :isShowDialog="isShowDialog"  
            @hide-dialog="toggleDialog"
            @refresh-data="fetchStoreUsers">
        </user-invite>

    </div>
</template>

<script>
import UserStore from "../../../../store/UserStore"

import DataLoading from '../../../../components/app/DataLoading'

import UserInvite from './UserInvite'

import axios from 'axios'

export default {

    components: {
        DataLoading,
        UserInvite
    },

    metaInfo: {
        title: 'Store Users'
    },

    data() {
        return {
            users: [],
            excludeEmails: ["admin@veecli.com","aby@tsits.com","saran.krishnan@veecli.com", "saran.poduval@gmail.com", "aby.thomas@veecli.com"],
            isPaginated: true,
            perPage: 10,
            isLoading: true,
            isFullPage: false,
            isShowDialog: false,
            action: '',
            storeId: 0,
            userId: 0,
            email: '',
            role: ''

        }
    },

    methods: {

        fetchStoreUsers() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/users'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.users = response.data.data

                        this.users = this.users.filter( (user) => {
    
                            if (this.excludeEmails.indexOf(user.email) < 0) {

                                return user.email
                                
                            }
                                
                            
                        })

                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        toggleDialog() {
            this.isShowDialog = ! this.isShowDialog
        },

        getDeleteToolTip(delname) {
            return 'Remove authorization for ' + delname
        },

        authorize(action,email="") {
            this.action = action
            this.email = email
            this.storeId = this.user.activeStore
            if (this.action === 'add') {
                this.toggleDialog()
            }
            if (this.action === 'remove') {
                this.$buefy.dialog.confirm({
                    title: 'Removing User Authorization',
                    message: 'Are you sure you want to <b>remove</b> the users authorization? This action cannot be undone.' + this.userId,
                    confirmText: 'Remove User Authorization',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => { 
                        this.postAuthorize()
                        this.$buefy.toast.open('Account deleted!')
                    }
                })                
            }
        },

        postAuthorize() {
            if (this.isShowDialog) this.toggleDialog()
            this.isLoading = true
            var url = process.env.VUE_APP_API_ROOT_URL + 'users/authorize'
            axios.post(url, {
                email: this.email,
                role: this.role,
                storeId: this.user.activeStore,
                action: this.action
            },
            {
                headers: {
                    'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                    'Refresh-Token': UserStore.getters.getRefreshToken
                },
            })
            .then( () => {
                this.fetchStoreUsers()
            })
            .catch ( (err) => {
                console.log(err.response.status)
            })
            this.isLoading = false
        },

    },

    computed: {
        // getUser() {
        //     return UserStore.getters.getUser
        // },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },
    },

    mounted() {
        this.user = this.getUser()
        if (this.getActiveStore.role != "STAFF") {
            this.fetchStoreUsers()
        }
    }

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>